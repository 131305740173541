<section
  class="products-section"
  [ngClass]="{
    'products-section-2': router.url === aboutUrl.about,
    'products-section': router.url === homeUrl.home
  }"
>
  <div
    class="title-row"
    [ngClass]="{
      'title-row-2': router.url === aboutUrl.about,
      'title-row': router.url === homeUrl.home
    }"
  >
    <h3>بعض مشاريعنا</h3>
    <img src="../../../../../assets/lamp-img.png" alt="" />
    <!-- <img src="assets/header-two/NI-05.png" alt="" /> -->
  </div>

  <!-- //////////////////////////////////////////////////////////////// -->

  <div class="container some-class" *ngIf="router.url === homeUrl.home">
    <div class="swiper-full-some-product">
      <div class="swiper-wrapper">
        <ng-container *ngFor="let slide of slides">
          <div class="swiper-slide">
            <div class="img-fluid">
              <video
                style="width: 100%; height: 100%; object-fit: cover"
                data-lazyload="true"
                secure="true"
                loop="true"
                autoplay
                muted="true"
                playsinline="true"
                id="video"
                #vid
                [src]="slide.image"
              >
                <!-- <source [src]="slide.image" type="video/webm" />
              <source [src]="slide.image" type="video/gif" /> -->
              </video>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- <div class="swiper-pagination"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </div>
  </div>

  <div class="container some-class" *ngIf="router.url === aboutUrl.about">
    <div class="swiper-full-some-festivals">
      <div class="swiper-wrapper">
        <ng-container *ngFor="let slide of slider">
          <div class="swiper-slide">
            <div class="img-fluid">
              <video
                style="width: 100%; height: 100%; object-fit: cover"
                data-lazyload="true"
                secure="true"
                loop="true"
                autoplay
                muted="true"
                playsinline="true"
                id="video"
                #vid
                [src]="slide.image"
              >
                <!-- <source [src]="slide.image" type="video/webm" />
              <source [src]="slide.image" type="video/gif" /> -->
              </video>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- <div class="swiper-pagination"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </div>
  </div>
</section>
