import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';
import { IfNotLoginGuard } from '../if-not-login.guard';
import { UserComponent } from './user.component';
import { ConfirmUnsubscribeComponent } from './pages/auth/confirm-unsubscribe/confirm-unsubscribe.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [MetaGuard],
    component: UserComponent,

    children: [
      {
        path: 'home',

        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },

      {
        path: 'home',
        component: HomeComponent,
      },

      {
        path: 'shop',
        loadChildren: () =>
          import('./pages/shop/shop.module').then((m) => m.ShopModule),
      },
      {
        path: 'gateway',
        loadChildren: () =>
          import('./pages/gateway/gateway.module').then((m) => m.GatewayModule),
      },
      {
        path: 'auth',
        canActivate: [IfNotLoginGuard],
        loadChildren: () =>
          import('./pages/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./pages/account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'how-to-subscription',
        loadChildren: () =>
          import('./pages/subscription-page/subscription-page.module').then(
            (m) => m.SubscriptionPageModule
          ),
      },
      {
        path: 'subscription-confirm',
        loadChildren: () =>
          import(
            './pages/subscription-confirm/subscription-confirm.module'
          ).then((m) => m.SubscriptionConfirmModule),
      },
      {
        path: 'order',
        loadChildren: () =>
          import('./pages/order/order.module').then((m) => m.OrderModule),
      },
      {
        path: 'congrats',
        loadChildren: () =>
          import('./pages/congrats/congrats.module').then(
            (m) => m.CongratsModule
          ),
      },
      {
        path: 'congrats/shop',
        loadChildren: () =>
          import('./pages/congrats/congrats.module').then(
            (m) => m.CongratsModule
          ),
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('./pages/privacy-policy/privacy-policy.module').then(
            (m) => m.PrivacyPolicyModule
          ),
      },
      {
        path: 'terms-of-use',
        loadChildren: () =>
          import('./pages/terms-of-use/terms-of-use.module').then(
            (m) => m.TermsOfUseModule
          ),
      },
      {
        path: 'about-us',
        loadChildren: () =>
          import('./pages/about-us/about-us.module').then(
            (m) => m.AboutUsModule
          ),
      },

      {
        path: 'schools',
        loadChildren: () =>
          import('./pages/schools/schools.module').then((m) => m.SchoolsModule),
      },
      // {
      //   path: 'promotions-offer',
      //   loadChildren: () => import('./pages/promotions-offer/promotions-offer.module').then(m => m.PromotionsOfferModule),
      // },
      {
        path: 'confirm-unsubscribe/:token',
        component: ConfirmUnsubscribeComponent,
      },
      {
        path: '**',
        loadChildren: () =>
          import('./pages/notfound/notfound.module').then(
            (m) => m.NotfoundModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
