<section class="poster-section">
  <div class="title-row">
    <!-- <img src="assets/header-two/NI-06.png" alt="" /> -->
    <div>
      <h3>لماذا برق؟</h3>
    </div>
    <!-- <img src="assets/header-two/NI-06.png" alt="" /> -->
  </div>

  <div class="swiper-full">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div class="desktop">
          <div class="img-fluid">
            <div class="slide-text-box">
              <div class="slide-text-title-3">
                <p>يساعدك برق على قضاء وقت ممتع مع أطفالك </p>
              </div>
              <div class="slide-text-subtitle">
                <p>
                  لتحفيز الإبداع الطبيعي للأطفال وفضولهم، مع مساعدة الاهل الذين
                  يرغبون في تقديم تجارب ثرية لأطفالهم
                </p>
              </div>
            </div>

            <div class="slide-image-box">
              <img src="../../../../../assets/why-barq.png" alt="" />
            </div>
          </div>
        </div>
        <div class="mobile">
          <div class="img-fluid">
            <div class="slide-text-box">
              <div class="slide-text-title-3">
                <p>يساعدك برق على قضاء وقت ممتع مع أطفالك </p>
              </div>

              <div class="slide-image-box">
                <img src="../../../../../assets/why-barq.png" alt="" />
              </div>
              <div class="slide-text-subtitle">
                <p>
                  لتحفيز الإبداع الطبيعي للأطفال وفضولهم، مع مساعدة الاهل الذين
                  يرغبون في تقديم تجارب ثرية لأطفالهم
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="desktop">
          <div class="img-fluid">
            <div class="slide-text-box">
              <div class="slide-text-title-2">
                <p>لتعليم أطفالنا عن طريق اللعب و الاستكشاف</p>
              </div>
              <div class="slide-text-subtitle">
                <p>
                  صندوق مليء بالمشاريع الفنية التعليمية المصممة خصيصا لتطوير
                  مهارات طفلك عن طريق اللعب التجريبي.
                </p>
              </div>
            </div>

            <div class="slide-image-box">
              <img src="../../../../../assets/why-barq.png" alt="" />
            </div>
          </div>
        </div>

        <div class="mobile">
          <div class="img-fluid">
            <div class="slide-text-box">
              <div class="slide-text-title-2">
                <p>لتعليم أطفالنا عن طريق اللعب و الاستكشاف</p>
              </div>

              <div class="slide-image-box">
                <img src="../../../../../assets/why-barq.png" alt="" />
              </div>
              <div class="slide-text-subtitle">
                <p>
                  صندوق مليء بالمشاريع الفنية التعليمية المصممة خصيصا لتطوير
                  مهارات طفلك عن طريق اللعب التجريبي.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="desktop">
          <div class="img-fluid">
            <div class="slide-text-box">
              <div class="slide-text-title-1">
                <p>لإلهام الجيل القادم من المبتكرين</p>
              </div>
              <div class="slide-text-subtitle-1">
                <p>
                  يحتاج العالم العربي إلى مزيد من الابتكار وحل المشكلات . لذلك
                  قررنا أن نبدأ على نطاق صغير - تعليم الأطفال الابتكار وحل
                  المشكلات
                </p>
              </div>
            </div>

            <div class="slide-image-box">
              <img src="../../../../../assets/why-barq.png" alt="" />
            </div>
          </div>
        </div>

        <div class="mobile">
          <div class="img-fluid">
            <div class="slide-text-box">
              <div class="slide-text-title-1">
                <p>لإلهام الجيل القادم من المبتكرين</p>
              </div>

              <div class="slide-image-box">
                <img src="../../../../../assets/why-barq.png" alt="" />
              </div>
              <div class="slide-text-subtitle-1">
                <p>
                  يحتاج العالم العربي إلى مزيد من الابتكار وحل المشكلات . لذلك
                  قررنا أن نبدأ على نطاق صغير - تعليم الأطفال الابتكار وحل
                  المشكلات
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="swiper-pagination"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div> -->
  </div>
</section>
